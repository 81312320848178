import gql from "graphql-tag";

export const CREATE_OFFER = gql`
  mutation createOffer(
    $type: String!
    $mediaType: Int
    $attributes: newOffer!
    $image: Upload!
  ) {
    createOffer(
      type: $type
      mediaType: $mediaType
      attributes: $attributes
      image: $image
    ) {
      id
      name
    }
  }
`;
export const REJECT_SUBSCRIPTION = gql`
  mutation rejectSubscription($id: [Int]) {
    rejectSubscription(id: $id) {
      state
      msg
    }
  }
`;
export const APPROVE_SUBSCRIPTION = gql`
  mutation approveSubscription($id: [Int]) {
    approveSubscription(id: $id) {
      state
    }
  }
`;
export const CAMPAIGN_DELIVERY_APPROVE = gql`
  mutation campaignDeliveryApprove($id: [Int]) {
    campaignDeliveryApprove(id: $id) {
      state
      msg
    }
  }
`;
export const CREATE_PAYMENT = gql`
  mutation createPaymentTransaction($nonce: String!, $amount: Float!) {
    createPaymentTransaction(nonce: $nonce, amount: $amount) {
      state
    }
  }
`;
export const CONFIRM_EMAIL = gql`
  mutation confirmEmail($pin: String) {
    confirmEmail(pin: $pin) {
      state
      msg
    }
  }
`;
export const CREATE_CAMPAIGN = gql`
  mutation createCampaign(
    $account_id: Int!
    $name: String!
    $type: String
    $description: String
    $influencers: Int
    $followers: Int
    $location: [Int]
    $category: [Int]
    $budget: Float!
    $priceStart: Int!
    $priceEnd: Int!
    $rating: Int
    $ageStart: Int
    $ageEnd: Int!
    $gender: String
    $posts: Int
    $mediaHandle: String
    $mediaTags: String
    $image: Upload!
    $tag: [Int]
  ) {
    createCampaign(
      account_id: $account_id
      name: $name
      type: $type
      description: $description
      influencers: $influencers
      followers: $followers
      location: $location
      category: $category
      budget: $budget
      priceStart: $priceStart
      priceEnd: $priceEnd
      rating: $rating
      ageStart: $ageStart
      ageEnd: $ageEnd
      gender: $gender
      posts: $posts
      mediaHandle: $mediaHandle
      mediaTags: $mediaTags
      image: $image
      tag: $tag
    ) {
      state
      msg
    }
  }
`;
