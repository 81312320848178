<template>
  <div class="billingHistory">
    <div class="container">
      <h1>{{$t('Billing history')}}</h1>
      <!-- purchases History -->
      <billing-summary />
      <div class="filtersOption">
        <div class="filtersOption__paymentOptions">
          <p>{{$t('Funds')}}:</p>
          <ul>
            <li>
              <a class="brand-add-money" href="javascript:void(0);" v-b-modal.cardMoney>+ {{$t('Add Money')}}</a>
            </li>
          </ul>
        </div>
        <div class="filtersOption__showsFilters">
          <billing-filters :filter-types="filterTypes" @filter="filters" />
        </div>
      </div>
      <div v-if="type == 'transactions'">
        <transactions :filter-date="filterDate"></transactions>
      </div>
      <div v-if="type == 'funds'">
        <funds :filter-date="filterDate" :refetch="refetch"></funds>
      </div>
    </div>

    <!-- modal1 -->
    <b-modal id="paypalAccount" content-class="accountModal">
      <div class="title">{{$t('Add Money with Your Paypal Account')}}</div>
      <base-touch-spin sign="$" :price="1" text=".00" />
      <b-form>
        <base-input
          :placeholder="$t('Email Address')"
          type="text"
          rules="required"
          name="Email Address"
        />
      </b-form>
      <p class="subTitle">
        {{$t('We will not be able to recover funds send to the wrong adress, please
        make sure the information you enter is correct')}}.
      </p>
      <base-checkbox-group
        name="story"
        :options="agreement"
        rules="required"
      ></base-checkbox-group>
      <button type="submit" class="btn btn-primary full">
        {{$t('Add Money with Your Paypal Account')}}
      </button>
    </b-modal>

    <!-- modal2 -->
    <b-modal
      id="cardMoney"
      centered
      no-close-on-backdrop
      ref="cardpayment"
      content-class="accountModal"
    >
      <div class="title">{{$t('Add Money with Card')}}</div>
      <base-touch-spin
        v-model="payment.amount"
        sign="$"
        :readonly="false"
        :price="50"
      />
      <base-brain-tree @submit="createPayment"></base-brain-tree>
      <label class="invalid" :class="invalidAmount == false ? 'd-none' : ''"
        >{{$t('Please enter valid amount to pay')}}.</label
      >
    </b-modal>
  </div>
</template>

<script>
import { CREATE_PAYMENT } from "../../graphql/brand/mutations";
import { MESSAGES } from "@/_helpers/notifications";
import { mapActions } from "vuex";
export default {
  components: {
    billingSummary: () =>
      import(
        /* webpackChunkName: "socialMedia" */ "@/components/brand/billing/BillingSummary.vue"
      ),
    BillingFilters: () =>
      import(
        /* webpackChunkName: "influencerDetailGallery" */ "@/components/common/billing/BillingFilters.vue"
      ),
    Transactions: () =>
      import(
        /* webpackChunkName: "influencerDetailGallery" */ "@/components/common/billing/Transactions.vue"
      ),
    Funds: () =>
      import(
        /* webpackChunkName: "influencerDetailGallery" */ "@/components/brand/billing/Funds.vue"
      ),
  },
  data() {
    return {
      agreement: "",
      type: "transactions",
      refetch: false,
      payment: {
        nonce: null,
        amount: 1,
      },
      filterTypes: [
        { value: "funds", text: this.$i18n.t('Funds') },
        { value: "transactions", text: this.$i18n.t('Transactions') },
      ],
      filterDate: {
        startDate: null,
        endDate: null,
      },
      invalidAmount: false,
    };
  },
  mounted() {
    this.getBalance();
  },

  methods: {
    ...mapActions(["getBalance"]),
    async createPayment(token) {
      this.payment.nonce = token;
      this.payment.amount = parseFloat(this.payment.amount);
      if (this.payment.amount <= 0 || this.payment.amount == "") {
        this.invalidAmount = true;
        return;
      }
      await this.$apollo
        .mutate({
          mutation: CREATE_PAYMENT,
          variables: this.payment,
        })
        .then((data) => {
          if (data) {
            if (data.data.createPaymentTransaction.state == "success") {
              this.$refs["cardpayment"].hide();
              this.notify("success", MESSAGES.PAYMENT_CREATED);
              this.getBalance();
              this.refetch = true;
            }
          }
        })
        .catch((e) => {
          this.handleError(e);
        });
    },
    filters: function (data) {
      this.type = data.type;
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.hasMore = true;
      this.infiniteId += 1;
      this.page = 1;
      (this.isFirst = true),
        (this.filterDate.startDate = data.startDate),
        (this.filterDate.endDate = data.endDate);
    },
  },
};
</script>

<style lang="scss" scoped>
.billingHistory {
  h1 {
    font-weight: 700;
  }
  .brand-add-money {
    background-color: #9637f1;
    color: #fff;
  }
}
.invalid {
  color: #dc3545;
  padding: 8px 0;
}

.filtersOption__paymentOptions {
  ul {
    li {
      a {
        width: 132px
      }
    }
  }
}

</style>
